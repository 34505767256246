import React from 'react'
import { useIntl } from 'react-intl'
import {Link} from 'react-scroll'

const HeroSection = () => {
    const intl = useIntl()

    return (
        <section className="hero">
            <div className="container">
                <h1 className="hero-title">
                    {intl.formatMessage({ id: 'hero.title' })}
                </h1>
                <p className="hero-subtitle">
                    {intl.formatMessage({ id: 'hero.subtitle' })}
                </p>
                <Link to="about" smooth={true} duration={500} className="button">
                    {intl.formatMessage({ id: 'hero.button' })}
                </Link>
            </div>
        </section>
    )
}

export default HeroSection