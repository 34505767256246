import React from 'react'
import { useIntl } from 'react-intl'

const CompaniesSection = () => {
    const intl = useIntl()

    const companies = [
        { id: 'aquaplus', url: 'https://aquaplus.am/' },
        { id: 'poolservice', url: 'https://poolservice.am/' },
        { id: 'aqualine', url: 'https://aqualine.am/' },
    ]

    return (
        <section id="companies" className="section">
            <div className="container">
                <h2 className="section-title">
                    {intl.formatMessage({id: 'companies.title'})}
                </h2>
                <div className="grid">
                    {companies.map((company) => (
                        <a
                            href={company.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="no-underline"
                            key={company.id}
                        >
                            <div className="card wave-container">
                                <div className="ocean">
                                    <div className="wave"></div>
                                    <div className="wave"></div>
                                </div>
                                <h3 className="card-title text-uppercase">
                                    {intl.formatMessage({id: `companies.${company.id}.name`})}
                                </h3>
                                <p>
                                    {intl.formatMessage({id: `companies.${company.id}.description`})}
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default CompaniesSection