import React from 'react'
import { useIntl } from 'react-intl'

const AboutSection = () => {
    const intl = useIntl()

    const values = [
        { id: 'customerCentricApproach', icon: '👨‍👩‍👧‍👦' },
        { id: 'qualityCraftsmanship', icon: '🔧' },
        { id: 'expertiseAndInnovation', icon: '💡' },
        { id: 'integrityAndTransparency', icon: '🤝' },
        { id: 'commitmentToSafety', icon: '🛡️' },
        { id: 'environmentalResponsibility', icon: '🌿' },
    ]

    return (
        <section id="about" className="section">
            <div className="container">
                <h2 className="section-title">
                    {intl.formatMessage({ id: 'about.title' })}
                </h2>
                <div className="grid">
                    {values.map((value, index) => (
                        <div key={index} className="card">
                            <div className="card-icon">{value.icon}</div>
                            <h3 className="card-title">
                                {intl.formatMessage({ id: `about.values.${value.id}.title` })}
                            </h3>
                            <p>
                                {intl.formatMessage({ id: `about.values.${value.id}.description` })}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default AboutSection