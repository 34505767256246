'use client'

import React, { useState, useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'
import { ThemeProvider } from './contexts/ThemeContext'
import Header from './components/Header'
import HeroSection from './components/HeroSection'
import AboutSection from './components/AboutSection'
import CompaniesSection from './components/CompaniesSection'
import ContactSection from './components/ContactSection'
import Footer from './components/Footer'
import en from './ii18n/en.json'
import ru from './ii18n/ru.json'
import am from './ii18n/am.json'
import { BrowserRouter } from 'react-router-dom';


const messages = { en, ru, am }
const flattenMessages = (messages, prefix = '') => {
    return Object.keys(messages).reduce((acc, key) => {
        const value = messages[key];
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof value === 'object') {
            Object.assign(acc, flattenMessages(value, newKey)); // Recursively flatten
        } else {
            acc[newKey] = value;
        }
        return acc;
    }, {});
};

export default function App() {
    const [language, setLanguage] = useState('en')
    const [theme, setTheme] = useState('light')

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme')
        if (savedTheme) {
            setTheme(savedTheme)
        } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setTheme('dark')
        }
    }, [])

    useEffect(() => {
        document.documentElement.classList.toggle('dark', theme === 'dark')
        localStorage.setItem('theme', theme)
    }, [theme])

    const toggleTheme = () => {
        setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light')
        return theme === 'light' ? 'dark' : 'light'
    }
    return (
      <BrowserRouter>
        <ThemeProvider>
            <AnimatePresence mode="wait">
                <motion.div
                    key={language}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                >
                  <IntlProvider messages={flattenMessages(messages[language])} locale={language} defaultLocale="en" onError={(err) => console.warn('Intl Error:', err)}>
                    <div className="min-h-screen flex flex-col bg-white dark:bg-gray-900 text-gray-900 dark:text-white transition-colors duration-300">
                      <Header language={language} setLanguage={setLanguage} theme={theme} toggleTheme={toggleTheme} />
                      <main className="flex-grow">
                        <HeroSection />
                        <CompaniesSection />
                        <AboutSection />
                        <ContactSection />
                      </main>
                      <Footer />
                    </div>
                  </IntlProvider>
                </motion.div>
            </AnimatePresence>
        </ThemeProvider>
      </BrowserRouter>
  )
}
