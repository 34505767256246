import React from 'react'
import { useIntl } from 'react-intl'

const Footer = () => {
    const intl = useIntl()

    return (
        <footer className="footer">
            <div className="container footer-content">
                <div>
                    <p>&copy; 2024 AQUAPROJECTS. {intl.formatMessage({ id: 'footer.copyright' })}</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer