import { Link } from 'react-scroll';
import {useIntl} from "react-intl";

import useLocalStorage from "use-local-storage";
const Header = ({ language, setLanguage, theme, toggleTheme }) => {
    const intl = useIntl();
    const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const [isDark, setIsDark] = useLocalStorage("isDark", preference);

    function changeTheme(isDark) {
        setIsDark(!isDark)
        toggleTheme();
    }

    return (
        <header className="header">
            <div className="container header-content">
                <a href="/" className="logo"> {/* Use a standard <a> for navigating to the homepage */}
                    AQUA PROJECTS
                </a>
                <nav className="nav">
                    <Link to="about" smooth={true} duration={500} className="nav-link">
                        {intl.formatMessage({id: "header.nav.about"})}
                    </Link>
                    <Link to="companies" smooth={true} duration={500} className="nav-link">
                        {intl.formatMessage({ id: 'header.nav.companies' })}
                    </Link>
                    <Link to="contact" smooth={true} duration={500} className="nav-link">
                        {intl.formatMessage({ id: 'header.nav.contact' })}
                    </Link>
                </nav>
                <div className="lang-switcher">
                    <label className="theme-switch relative inline-flex items-center cursor-pointer">
                        <input
                            type="checkbox"
                            checked={theme === 'dark'} // Ensure checkbox reflects the current theme
                            onChange={toggleTheme} // Trigger the toggle on change
                            className="sr-only"
                        />
                        <div className="slider bg-gray-200 dark:bg-gray-700 rounded-full w-14 h-8 transition-all">
                <span
                    className={`dot absolute top-1 left-1 bg-white dark:bg-yellow-500 w-6 h-6 rounded-full shadow-md transition-transform ${
                        theme === 'dark' ? 'transform translate-x-6' : ''
                    }`}
                ></span>
                        </div>
                    </label>
                    {['en', 'ru', 'am'].map((lang) => (
                        <button
                            key={lang}
                            onClick={() => setLanguage(lang)}
                            className={`lang-button ${language === lang ? 'active' : ''}`}
                        >
                            {lang.toUpperCase()}
                        </button>
                    ))}
                </div>
            </div>
        </header>
    );
};

export default Header;
