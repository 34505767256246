import React from 'react'
import { useIntl } from 'react-intl'
import {APIProvider, Map} from '@vis.gl/react-google-maps';
import {Marker} from './MarkerComponent';

const ContactSection = () => {
    const intl = useIntl()

    return (
        <section id="contact" className="section">
            {/*<div className="advanced-marker-example">
                <APIProvider apiKey={`AIzaSyDQqS4LGJzG2H_SLM6v0WRXncXlb95iq4I`}>
                    <Map
                        style={{width: '100vw', height: '100vh'}}
                        defaultCenter={{lat: 22.54992, lng: 0}}
                        defaultZoom={3}
                        gestureHandling={'greedy'}
                        disableDefaultUI={true}
                    />
                </APIProvider>
            </div>*/}

            <div className="container">
                <h2 className="section-title">
                    {intl.formatMessage({id: 'contact.title'})}
                </h2>
                {/*<form className="form">
                    <div className="form-group">
                        <label htmlFor="name" className="form-label">
                            {intl.formatMessage({id: 'contact.form.name'})}
                        </label>
                        <input
                            type="text"
                            id="name"
                            className="form-input"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="form-label">
                            {intl.formatMessage({id: 'contact.form.email'})}
                        </label>
                        <input
                            type="email"
                            id="email"
                            className="form-input"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="form-label">
                            {intl.formatMessage({id: 'contact.form.message'})}
                        </label>
                        <textarea
                            id="message"
                            rows={4}
                            className="form-input"
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="form-submit">
                        {intl.formatMessage({id: 'contact.form.submit'})}
                    </button>
                </form>*/}
                <div
                    className="contact-container"
                >
                    {/* Contact Info Card */}
                    <div
                        className="card"
                        style={{
                            flex: '1 1 45%',
                            maxWidth: '600px',
                            minWidth: '300px',
                            padding: '15px',
                        }}
                    >
                        <div className="card-wrapper">
                            <div className="text-wrapper">
                                <ul style={{
                                    listStyle: 'none',
                                    padding: 0,
                                    margin: 0,
                                    fontSize: '14px',
                                    lineHeight: '1.6'
                                }}>
                                    <li style={{marginBottom: '10px'}}>
                                        <strong>Phone:</strong>{' '}
                                        <a href="tel:37410542105" className="no-underline">
                                            (374) 33 42-48-55
                                        </a>
                                    </li>
                                    <li style={{marginBottom: '10px'}}>
                                        <strong>WhatsApp:</strong>{' '}
                                        <a href="tel:37410542105" className="no-underline">
                                            (374) 44 22-03-03
                                        </a>
                                    </li>
                                    <li style={{marginBottom: '10px'}}>
                                        <strong>Email:</strong>{' '}
                                        <a href="mailto:info@aquaprojects.am"
                                           className="no-underline">
                                            info@aquaprojects.am
                                        </a>
                                    </li>
                                    <li style={{marginBottom: '10px'}}>
                                        <strong>Address:</strong> 52 Yuri Erznkyan, Yerevan, Armenia
                                    </li>
                                    <li>
                                        <strong>Working Hours:</strong> Mon-Fri: 10am - 7pm
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Google Map */}
                    <div
                        className="map-wrapper"
                        style={{
                            flex: '1 1 45%',
                            minWidth: '300px',
                        }}
                    >
                        <div
                            className="google-map"
                            style={{
                                width: '100%',
                                height: '100%',
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                            }}
                        >
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d347.22735742217037!2d44.48538704218609!3d40.21395305702432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406a9704b9247f31%3A0x22c28b64b30c891f!2sAqua%20Projects!5e0!3m2!1sen!2sam!4v1732202891351!5m2!1sen!2sam"
                                width="100%" height="100%" style={{border:0}} allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection